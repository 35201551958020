/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { createContext, useReducer, useEffect } from 'react';
import axios from 'axios';

interface NewsItem {
  title: string;
  date: string;
  image: string | null;
  slug: string;
  type: string;
  descriptionLink: string;
}

interface NewsState {
  news: NewsItem[];
}

interface NewsAction {
  type: 'ADD_NEWS';
  payload: NewsItem[];
}

const initialState: NewsState = {
  news: [],
};

const NewsContext = createContext<{
  state: NewsState;
  dispatch: React.Dispatch<NewsAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

const newsReducer = (state: NewsState, action: NewsAction): NewsState => {
  switch (action.type) {
    case 'ADD_NEWS':
      return {
        ...state,
        news: [...state.news, ...action.payload],
      };
    default:
      return state;
  }
};

export const NewsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(newsReducer, initialState);

  useEffect(() => {
    const fetchNews = async () => {
      const fetchAndAddNews = async (url: string) => {
        try {
          const response = await axios.get<NewsItem[]>(url);
          dispatch({ type: 'ADD_NEWS', payload: response.data });
        } catch (error) {
          console.error(`Error fetching news from ${url}:`, error);
        }
      };

      // Выполняем запросы параллельно, но обрабатываем их независимо друг от друга

      // fetchAndAddNews(`${process.env.REACT_APP_API_URL!}/parse-apk-news`);
      fetchAndAddNews(`${process.env.REACT_APP_API_URL!}/parse-news`);
      // fetchAndAddNews(`${process.env.REACT_APP_API_URL!}/api/news`);
      fetchAndAddNews(`${process.env.REACT_APP_API_URL!}/parse-fsa-news`);
      fetchAndAddNews(`${process.env.REACT_APP_API_URL!}/parse-usda-news`);
    };

    fetchNews();
  }, []);

  return (
    <NewsContext.Provider value={{ state, dispatch }}>
      {children}
    </NewsContext.Provider>
  );
};

export default NewsContext;
